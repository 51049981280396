import {postPayload} from "./data";
import {ZOORIX_REF_CODE} from "./partners";

export const INIT_PLANS = 'INIT_PLANS';
export const SET_LOADING_PLANS = 'SET_LOADING_PLANS';
export const PLATINUM_PLUS_CHANGE_RANGE = 'PLATINUM_PLUS_CHANGE_RANGE';
export const INIT_PLANS_DATA = 'INIT_PLANS_DATA';
export const PLANS_SET_DISCOUNT = 'PLANS_SET_DISCOUNT';
export const DIAMOND_CHANGE_RANGE = 'DIAMOND_CHANGE_RANGE';
export const PLAT_PLUS_BETA_CHANGE_RANGE = 'PLAT_PLUS_BETA_CHANGE_RANGE';
export const SET_LOADING_WHITE_LABEL = 'SET_LOADING_WHITE_LABEL';
export const PLANS_SET_SUBMITTING_PROMO_CODE = 'PLANS_SET_SUBMITTING_PROMO_CODE';
export const PLANS_PROMO_CODE_CHANGE = 'PLANS_PROMO_CODE_CHANGE';

export const initPlans = () => ({
    type: INIT_PLANS,
});

export const setLoadingPlans = () => ({
    type: SET_LOADING_PLANS,
});

export const platinumPlusChangeRange = value => ({
    type: PLATINUM_PLUS_CHANGE_RANGE,
    value,
});

export const initPlansData = json => ({
    type: INIT_PLANS_DATA,
    json,
});

export const plansSetDiscount = json => ({
    type: PLANS_SET_DISCOUNT,
    json,
});

export const diamondChangeRange = value => ({
    type: DIAMOND_CHANGE_RANGE,
    value,
});

export const platPlusBetaChangeRange = value => ({
    type: PLAT_PLUS_BETA_CHANGE_RANGE,
    value,
});

export const setLoadingWhiteLabel = bool => ({
    type: SET_LOADING_WHITE_LABEL,
    bool,
});

export const plansSetSubmittingPromoCode = bool => ({
    type: PLANS_SET_SUBMITTING_PROMO_CODE,
    bool,
});

export const plansPromoCodeChange = text => ({
    type: PLANS_PROMO_CODE_CHANGE,
    text,
});

export const loadPlansIfNeeded = () => async (dispatch, getState) => {
    const state = getState();
    if (state.plans.loading) return;
    dispatch(setLoadingPlans());
    const res = await postPayload('data/get_data_for_plans_tab', {}, state);
    if (res.ok) {
        const json = await res.json();
        dispatch(initPlansData(json));
    } else {
        console.log('loadPlansIfNeeded failed');
    }
};

export const plansSetWhiteLabel = bool => async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingWhiteLabel(true));
    const res = await postPayload('data/set_powered_by_white_label', {
        bool,
    }, state);
    if (res.ok) {
        const json = await res.json();
        dispatch(initPlansData(json));
    } else {
        console.log('plansSetWhiteLabel failed');
    }
    dispatch(setLoadingWhiteLabel(false));
};

export const plansSubmitPromoCode = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(plansSetSubmittingPromoCode(true));
    try {
        localStorage.removeItem(ZOORIX_REF_CODE);
    } catch (e) {
        console.log('unable to remove ref code', e)
    }
    const res = await postPayload('data/set_promo_code', {
        promo_code: state.plans.promoCode,
    }, state);
    if (res.ok) {
        const json = await res.json();
        dispatch(plansSetDiscount(json));
    } else {
        console.log('plansSubmitPromoCode failed');
    }
    dispatch(plansSetSubmittingPromoCode(false));
};
