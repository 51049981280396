import ActionCable from 'actioncable';
import {INSTALLATION_STATUS_UPDATE, installationStatusUpdate, ONLINE_STORE2, onlineStore2} from "./actions/status";
import {host} from "./actions/data";
import {SYNC_PRODUCT_TRANS_STATUS, syncProductTransStatus} from "./actions/texts";
import {LOAD_ADMIN_MAPPING_SEARCH, loadAdminMappingSearch} from "./actions/products";
import {
    FETCH_PRODUCTS_COMPLETE, wizardCreateStoreJsDone, wizardCreateStoreJsProgress,
    wizardSetOfferStageReady,
    wizardSetTriggerStageReady
} from "./actions/v2/appV2Wizard";

export const SUBSCRIBE_TO_INSTALLATION_STATUS = 'SUBSCRIBE_TO_INSTALLATION_STATUS';

export const subscribeToInstallationStatus = (shopName, token, appType) => ({
    type: SUBSCRIBE_TO_INSTALLATION_STATUS,
    shopName,
    token,
    appType,
});

export default function installationStatusMiddleware() {
    const cable = ActionCable.createConsumer(`wss://${host}/installation_status`);

    return ({ dispatch, getState }) => next => action => {
        if (action.type === SUBSCRIBE_TO_INSTALLATION_STATUS) {
            cable.subscriptions.create({channel: 'InstallationStatusChannel', shop_name: action.shopName,
                                        token: action.token, app_type: action.appType}, {
                received: data => {
                    if (data.action_type === INSTALLATION_STATUS_UPDATE) {
                        dispatch(installationStatusUpdate(data));
                        if (data.progress === 100) {
                            dispatch(wizardCreateStoreJsDone(data.uuid));
                        } else {
                            dispatch(wizardCreateStoreJsProgress(data));
                        }
                    } else if (data.action_type === SYNC_PRODUCT_TRANS_STATUS) {
                        dispatch(syncProductTransStatus(data));
                    } else if (data.action_type === LOAD_ADMIN_MAPPING_SEARCH) {
                        dispatch(loadAdminMappingSearch(data));
                    } else if (data.action_type === ONLINE_STORE2) {
                        dispatch(onlineStore2(data));
                    } else if (data.action_type === FETCH_PRODUCTS_COMPLETE) {
                        if (data.stage === 'trigger') {
                            dispatch(wizardSetTriggerStageReady(data.uuid, data.handle));
                        } else if (data.stage === 'config_offer') {
                            dispatch(wizardSetOfferStageReady(data.uuid, data.handle));
                        }
                    } else {
                        console.log('unknown msg received:', data);
                    }
                }
            });
        }
        return next(action);
    };
}
