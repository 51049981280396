import fetch from "cross-fetch";
import {host} from "./data";
import {refreshSyncStatus} from "./status";

export const INIT_CART_DRAWER_SETTINGS = 'INIT_CART_DRAWER_SETTINGS';
export const RESTORE_CART_DRAWER_TO_DEFAULTS = 'RESTORE_CART_DRAWER_TO_DEFAULTS';
export const SAVE_CART_DRAWER_SETTINGS_PROCESSING = 'SAVE_CART_DRAWER_SETTINGS_PROCESSING';
export const SAVE_CART_DRAWER_SETTINGS_ERROR = 'SAVE_CART_DRAWER_SETTINGS_ERROR';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';
export const SAVE_CART_DRAWER_SETTINGS_SUCCESS = 'SAVE_CART_DRAWER_SETTINGS_SUCCESS';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const INVOKE_FROM_HEADER_CHANGE = 'INVOKE_FROM_HEADER_CHANGE';
export const CART_DRAWER_SETTINGS_BOOL_CHANGE = 'CART_DRAWER_SETTINGS_BOOL_CHANGE';
export const FREE_DISCOUNT_THRESHOLD_CHANGE = 'FREE_DISCOUNT_THRESHOLD_CHANGE';
export const FREE_SHIPPING_THRESHOLD_CHANGE = 'FREE_SHIPPING_THRESHOLD_CHANGE';
export const FREE_DISCOUNT_AMOUNT_CHANGE = 'FREE_DISCOUNT_AMOUNT_CHANGE';
export const INCENTIVE_CHANGE = 'INCENTIVE_CHANGE';
export const SHOW_BUTTONS_CHANGE = 'SHOW_BUTTONS_CHANGE';
export const MINI_CART_DISPLAY_ON_HOME_PAGE_CHANGE = 'MINI_CART_DISPLAY_ON_HOME_PAGE_CHANGE';
export const MINI_CART_OPEN_AFTER_ADC_CHANGE = 'MINI_CART_OPEN_AFTER_ADC_CHANGE';
export const MINI_CART_NOTE_CHANGE = 'MINI_CART_NOTE_CHANGE';
export const MINI_CART_ONE_CLICK_OFFER_ON_EMPTY_CART_CHANGE = 'MINI_CART_ONE_CLICK_OFFER_ON_EMPTY_CART_CHANGE';
export const PROMO_BAR_COLOR_CHANGE = 'PROMO_BAR_COLOR_CHANGE';
export const LEFT_TO_SPEND_COLOR_CHANGE = 'LEFT_TO_SPEND_COLOR_CHANGE';
export const CHECKOUT_BUTTON_COLOR_CHANGE = 'CHECKOUT_BUTTON_COLOR_CHANGE';
export const FLOATING_ICON_BACKGROUND_COLOR_CHANGE = 'FLOATING_ICON_BACKGROUND_COLOR_CHANGE';
export const FLOATING_ICON_COLOR_CHANGE = 'FLOATING_ICON_COLOR_CHANGE';
export const MINI_CART_VIEW_CART_COLOR_CHANGE = 'MINI_CART_VIEW_CART_COLOR_CHANGE';

export const initCartDrawerSettings = json => ({
    type: INIT_CART_DRAWER_SETTINGS,
    settings: json.cart_drawer_settings,
    currencySymbol: json.currency_symbol,
    planName: json.plan_name,
});

export const invokeFromHeaderChange = bool => ({
    type: INVOKE_FROM_HEADER_CHANGE,
    bool,
});

export const cartDrawerSettingsBoolChange = (attr, text) => ({
    type: CART_DRAWER_SETTINGS_BOOL_CHANGE,
    attr,
    text,
});

export const freeDiscountThresholdChange = num => ({
    type: FREE_DISCOUNT_THRESHOLD_CHANGE,
    num,
});

export const freeShippingThresholdChange = num => ({
    type: FREE_SHIPPING_THRESHOLD_CHANGE,
    num,
});

export const freeDiscountAmountChange = num => ({
    type: FREE_DISCOUNT_AMOUNT_CHANGE,
    num,
});

export const incentiveChange = text => ({
    type: INCENTIVE_CHANGE,
    text,
});

export const showButtonsChange = text => ({
    type: SHOW_BUTTONS_CHANGE,
    text,
});

export const miniCartDisplayOnHomePageChange = text => ({
    type: MINI_CART_DISPLAY_ON_HOME_PAGE_CHANGE,
    text,
});

export const miniCartOpenAfterAdcChange = text => ({
    type: MINI_CART_OPEN_AFTER_ADC_CHANGE,
    text,
});

export const miniCartNoteChange = text => ({
    type: MINI_CART_NOTE_CHANGE,
    text,
});

export const miniCartOneClickOfferOnEmptyCartChange = text => ({
    type: MINI_CART_ONE_CLICK_OFFER_ON_EMPTY_CART_CHANGE,
    text,
});

export const promoBarColorChange = hex => ({
    type: PROMO_BAR_COLOR_CHANGE,
    hex,
});

export const leftToSpendColorChange = hex => ({
    type: LEFT_TO_SPEND_COLOR_CHANGE,
    hex,
});

export const checkoutButtonColorChange = hex => ({
    type: CHECKOUT_BUTTON_COLOR_CHANGE,
    hex,
});

export const floatingIconBackgroundColorChange = hex => ({
    type: FLOATING_ICON_BACKGROUND_COLOR_CHANGE,
    hex,
});

export const floatingIconColorChange = hex => ({
    type: FLOATING_ICON_COLOR_CHANGE,
    hex,
});

export const miniCartViewCartColorChange = hex => ({
    type: MINI_CART_VIEW_CART_COLOR_CHANGE,
    hex,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const restoreCartDrawerToDefaults = () => ({
    type: RESTORE_CART_DRAWER_TO_DEFAULTS,
});

export const activateToast = () => ({
    type: ACTIVATE_TOAST,
});

export const saveCartDrawerSettingsProcessing = () => ({
    type: SAVE_CART_DRAWER_SETTINGS_PROCESSING,
});

export const saveCartDrawerSettingsError = () => ({
    type: SAVE_CART_DRAWER_SETTINGS_ERROR,
});

export const saveCartDrawerSettingsSuccess = () => ({
    type: SAVE_CART_DRAWER_SETTINGS_SUCCESS,
});

export function save() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveCartDrawerSettingsProcessing());
        return fetch(`https://${host}/cart_drawer/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    settings: state.settingsCartDrawer.settings,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(activateToast());
                        dispatch(saveCartDrawerSettingsSuccess());
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(saveCartDrawerSettingsError());
                }
            });

    }
}

