import { connect } from 'react-redux'
import ChooseRules from "../../components/products/ChooseRules";
import {
    backToMain,
    onGlobalsAbovePriceChange,
    onGlobalsAboveQuantityChange,
    onGlobalsBelowPriceChange,
    onGlobalsBelowQuantityChange,
    onShowGlobalsAbovePriceChanged,
    onShowGlobalsAboveQuantityChanged,
    onShowGlobalsBelowPriceChanged,
    onShowGlobalsBelowQuantityChanged,
    onShowGlobalsOnCartChanged,
    onShowGlobalsOnProductChanged,
    saveGlobals,
    showGlobal
} from "../../actions/products";

const mapStateToProps = state => ({
    nextError: state.products.saveError,
    nextText: 'Save',
    isNextLoading: state.products.isSavingGlobals,
    currencySymbol: state.products.currencySymbol,
    isShowOnProduct: state.products.isShowGlobalsOnProduct,
    isShowOnCart: state.products.isShowGlobalsOnCart,
    isShowAbovePrice: state.products.isShowGlobalsAbovePrice,
    isShowAboveQuantity: state.products.isShowGlobalsAboveQuantity,
    isShowBelowPrice: state.products.isShowGlobalsBelowPrice,
    isShowBelowQuantity: state.products.isShowGlobalsBelowQuantity,
    abovePrice: state.products.globalsAbovePrice,
    belowPrice: state.products.globalsBelowPrice,
    aboveQuantity: state.products.globalsAboveQuantity,
    belowQuantity: state.products.globalsBelowQuantity,

    showSave: false,
});

const mapDispatchToProps = (dispatch) => ({
    next: () => dispatch(saveGlobals()),
    cancel: () => dispatch(backToMain()),
    back: () => dispatch(showGlobal()),
    onShowOnProductChanged: bool => dispatch(onShowGlobalsOnProductChanged(bool)),
    onShowOnCartChanged: bool => dispatch(onShowGlobalsOnCartChanged(bool)),
    onShowAbovePriceChanged: bool => dispatch(onShowGlobalsAbovePriceChanged(bool)),
    onShowBelowPriceChanged: bool => dispatch(onShowGlobalsBelowPriceChanged(bool)),
    onShowBelowQuantityChanged: bool => dispatch(onShowGlobalsBelowQuantityChanged(bool)),
    onShowAboveQuantityChanged: bool => dispatch(onShowGlobalsAboveQuantityChanged(bool)),
    onAbovePriceChange: num => dispatch(onGlobalsAbovePriceChange(num)),
    onBelowPriceChange: num => dispatch(onGlobalsBelowPriceChange(num)),
    onAboveQuantityChange: num => dispatch(onGlobalsAboveQuantityChange(num)),
    onBelowQuantityChange: num => dispatch(onGlobalsBelowQuantityChange(num)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChooseRules);
