import React from 'react';
import {Button, Checkbox, List, Spinner, Modal, TextField} from "@shopify/polaris";
import '../../css/ProductsSlider.css'
import CsvUpload from "../CsvUpload";
import {host} from "../../actions/data";

const ImportMappings = ({
    backToMain, importMappingsState, uploadSuccess, uploadFailure, shopName, token, products,
    overrideExistingMappingsOnImportChange, toggleOverrideExistingMappingsModal,
    overrideExistingMappingsOnImport, data, onEmailChange, exportMappings
}) => (
    <div className="import-export-mapping-wrapper">
        <span className="navigation-button">
            <Button onClick={backToMain}>Back</Button>
        </span>
        <div className="import-mapping-wrapper">
            <div className="import-title">Import</div>
            <div className="import-example-csv">Use product handles to refer products - here's an <a
                href="https://public.zoorix.com/csv/import_mappings2.csv">example CSV</a>.
            </div>
            {(!importMappingsState || !importMappingsState.status || importMappingsState.status === 'done' ||
            importMappingsState.status === 'errors') &&
            <div>
                {importMappingsState && importMappingsState.status === 'done' && <div>
                    <span className="upload-status">Last upload was successful.</span>
                </div>}
                {importMappingsState && importMappingsState.status === 'errors' && <div>
                    <div className="upload-failed-msg"><span className="upload-status">Upload failed.</span> Here are
                        the errors:
                    </div>
                    <List>{importMappingsState.errors_text.split('\n').map((item, index) =>
                        <List.Item key={index}>
                            {item}
                        </List.Item>)}</List>
                </div>}
                <div className="upload-csv-msg">Upload a CSV:</div>
                <CsvUpload
                    presignedUrl={`https://${host}/import_mappings/get_url_for_csv?shop_name=${shopName}&token=${token}`}
                    uploadSuccess={uploadSuccess}
                    uploadFailure={uploadFailure}
                    afterButtonDiv={
                        <div>
                            <Checkbox label={'Override exising mappings'}
                                      checked={products.overrideExistingMappingsOnImport}
                                      onChange={overrideExistingMappingsOnImportChange}/>
                        </div>}
                />
            </div>}
            {importMappingsState && importMappingsState.status === 'processing' && <div className="csv-processing">
                <span className="csv-processing-text">Processing CSV...</span>
                <Spinner size="small"/>
            </div>}
            {importMappingsState && importMappingsState.status === 'failure' && <div>
                There was an error. Please refresh.
            </div>}
        </div>

        <div className="export-mapping-wrapper">
            <div className="export-title">Export</div>

            <TextField label={'Email to send CSV export file:'} onChange={onEmailChange} value={data.ownerEmail}/>
            <div className="export-btn">
                <Button primary={true} onClick={exportMappings} loading={products.exportingMappings}>Export</Button>
            </div>
            <div className="export-message">
                {products.exportMappingsStatus}
            </div>
        </div>

        <Modal open={products.overrideExisingMappingsOnImportModalOpen}
               onClose={toggleOverrideExistingMappingsModal(false)}
               title={'Override exising mappings'}
               primaryAction={{
                   content: 'Cancel',
                   onAction: toggleOverrideExistingMappingsModal(false),
               }}
               secondaryActions={[
                   {
                       content: 'Override',
                       onAction: overrideExistingMappingsOnImport,
                   },
               ]}>

            <Modal.Section>
                Warning! All of your mappings will be deleted, this action cannot be undone!
            </Modal.Section>
        </Modal>
    </div>
);

export default ImportMappings;
