import fetch from 'cross-fetch';
import { initStatus, setTimeoutRefreshSyncStatus } from "./status";
import { initSettings } from "./settings";
import {
    initAnalBogos,
    initAnalBundles,
    initAnalMappings,
    initAnalMixAndMatches,
    initAnalVolumeDiscounts,
    initAnalytics, initAnalyticsFailed
} from "./analytics";
import { initNav } from "./nav";
import { initProductsBundle } from "./productsBundle";
import { initBundleSettings } from "./settingsBundle";
import { initCssEditor } from "./cssEditor";
import { appSettingsInit } from "./settingsApp";
import { initCartDrawerSettings } from "./settingsCartDrawer";
import { subscribeToInstallationStatus } from "../installationStatusMiddleware";
import { volumeSettingsInit } from "./settingsVolume";
import { initPlans } from "./plans";
import { ZOORIX_REF_CODE } from "./partners";
import {
    adminTrack,
    getAllTags,
    getOfferPlacementAndOs2, getZoorixEnabled,
    initializeAppV2Wizard, wizardSetZoorixEnabledLoading,
} from "./v2/appV2Wizard";
import { initMixAndMatch } from "./mixAndMatch";
import {initBogoSettings} from "./bogo";

export const INIT_DATA = 'INIT_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';
export const SET_ZOORIX_M_INSTALLED = 'SET_ZOORIX_M_INSTALLED';
export const SET_WIZARD_BOOL = 'SET_WIZARD_BOOL';
export const NAME_CHANGE = 'NAME_CHANGE';
export const EMAIL_CHANGE = 'EMAIL_CHANGE';
export const UPDATE_ZOORIX_TOAST = 'UPDATE_ZOORIX_TOAST';
export const DISMISS_OUT_OF_DATE_TOAST = 'DISMISS_OUT_OF_DATE_TOAST';
export const ZOORIX_SHOPIFY_DOMAIN = 'zoorix_shopify_domain';
export const SET_LANDING_PAGE_VISIBLE = 'SET_LANDING_PAGE_VISIBLE';
export const SET_CRISP_LOADED = 'SET_CRISP_LOADED';
export const SET_CREDS = 'SET_CREDS';
export const SET_PASSWORD_ENABLED = 'SET_PASSWORD_ENABLED';

export const initData = (appType) => ({
    type: INIT_DATA,
    appType,
});

export const setPasswordEnabled = bool => ({
    type: SET_PASSWORD_ENABLED,
    bool,
});


export const receiveData = (json, appType) => ({
    type: RECEIVE_DATA,
    ownerName: json.ownerName,
    ownerEmail: json.ownerEmail,
    shopName: json.shopName,
    token: json.token,
    token_id: json.token_id,
    passwordEnabled: json.password_enabled,
    showAdminWizard: json.show_admin_wizard,
    referralOfferingDiscount: json.referral_offering_discount,
    upgradeUrl: json.upgrade_url,
    appType,
    json,
});

export const setZoorixMInstalled = bool => ({
    type: SET_ZOORIX_M_INSTALLED,
    bool,
});

export const setWizardBool = bool => ({
    type: SET_WIZARD_BOOL,
    bool,
});

export const setLandingPageVisible = bool => ({
    type: SET_LANDING_PAGE_VISIBLE,
    bool,
});

export const setCrispLoaded = bool => ({
    type: SET_CRISP_LOADED,
    bool,
});

export const setCreds = (shopName, token) => ({
    type: SET_CREDS,
    shopName,
    token,
});

export const devHost = '65eb-188-64-207-189.ngrok.io';
// export const host = process.env.NODE_ENV === 'development' ? devHost : 'api.zoorix.com';
// export const host = process.env.NODE_ENV === 'development' ? 'zoorix.loca.lt' : 'api.zoorix.com';
export const host = 'api.zoorix.com';
export const enableAppEmbedsDeepLink = shopName => `https://${shopName}/admin/themes/current/editor?context=apps&template=product&activateAppId=51fffd07-f9df-4d6a-b901-6d14b243dcad%2Fzoorix-head`;

export const getInitDataUrl = (shopName, token, refCode, appType) => `https://${host}/get_init_data?shop_name=${shopName}&token=${token}&version=2${refCode ? `&ref_code=${refCode}` : ''}${appType ? `&app_type=${appType}` : ''}`

function redirectToInstallUrl(json) {
    let disable_install_redirect = false;
    try {
        disable_install_redirect = localStorage.getItem('disable_install_redirect');
    } catch (e) {
        console.log('unable to fetch disable_install_redirect', e);
    }
    if (json.install_url && !disable_install_redirect) {
        window.open(json.install_url, '_parent');
    }
}

function fetchData(shopName, token) {
    return (dispatch, getState) => {
        window.setInterval(() => {
            dispatch(checkIfClientIsUpToDate(shopName, token));
        }, 60_000);
        dispatch(initData());
        dispatch(wizardSetZoorixEnabledLoading(true));
        dispatch(initNav({ installation: {} }));
        dispatch(subscribeToInstallationStatus(shopName, token));
        const lastMonth = new Date();
        lastMonth.setDate(lastMonth.getDate() - 30);
        fetch(`https://${host}/data/get_anal_data_v2?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(response => response.json())
            .then(json => dispatch(initAnalytics(json)))
            .catch(_ => dispatch(initAnalyticsFailed()));
        fetch(`https://${host}/data/get_anal_mappings?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalMappings(json)));
        fetch(`https://${host}/data/get_anal_bundles?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalBundles(json)));
        fetch(`https://${host}/data/get_anal_volume_discounts?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalVolumeDiscounts(json)));
        fetch(`https://${host}/data/get_anal_mix_and_matches?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalMixAndMatches(json)));
        fetch(`https://${host}/data/get_anal_bogos?shop_name=${shopName}&token=${token}&start_date=${lastMonth.toISOString()}&end_date=${new Date().toISOString()}`)
            .then(resp => resp.json())
            .then(json => dispatch(initAnalBogos(json)));
        let refCode = null;
        try {
            refCode = localStorage.getItem(ZOORIX_REF_CODE);
        } catch (e) {
            console.log('unable to get refCode', e)
        }
        return fetch(getInitDataUrl(shopName, token, refCode))
            .then(response => response.json())
            .then(json => {
                // if (json.plan_name === 'trial') {
                //     adaptHeightForTrail();
                // }

                redirectToInstallUrl(json);
                dispatch(pollForZoorixMInstalled(json.shopName));
                dispatch(initializeAppV2Wizard(json));
                dispatch(getAllTags(shopName, token));
                dispatch(receiveData(json));
                dispatch(initNav(json));
                dispatch(initStatus(json));
                dispatch(initSettings(json));
                dispatch(appSettingsInit(json));
                dispatch(initProductsBundle(json));
                dispatch(initBundleSettings(json));
                dispatch(initCartDrawerSettings(json));
                dispatch(volumeSettingsInit(json));
                dispatch(initCssEditor(json));
                dispatch(initPlans());
                postPayload('data/bundle_eligibility', {}, getState()).then(res => res.json()).then(eligibilityJson => {
                    dispatch(initMixAndMatch(json, eligibilityJson));
                })
                dispatch(initBogoSettings(json));
                setTimeoutRefreshSyncStatus(dispatch);
                dispatch(getOfferPlacementAndOs2());
                dispatch(getZoorixEnabled());
                dispatch(adminTrack('admin_main_page_loaded'));
            })
    }
}

// function adaptHeightForTrail(){
//     let root = document.querySelector('#root');
//     root.style.height = 'calc(100% - 70px)';
//     root.style.overflowX = 'scroll';
// }

function shouldFetchPostsData(state) {
    return !state.data.isFetched && !state.data.isFetching;
}

export function loadCrisp() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.data.crispLoaded) return;
        dispatch(setCrispLoaded(true));
        const d = document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        d.getElementsByTagName("head")[0].appendChild(s);
    }
}

export function fetchDataIfNeeded(shopName, token, appType) {
    return (dispatch, getState) => {
        if (shouldFetchPostsData(getState())) {
            if (!shopName) {
                shopName = new URLSearchParams(window.location.search).get('shop');
            }
            if (!token) {
                token = document.querySelector('#zrx-token').dataset.zrxToken;
            }
            try {
                localStorage.setItem(ZOORIX_SHOPIFY_DOMAIN, shopName);
            } catch (e) {
                console.log('unable to set shop name', e);
            }

            // try {
            //     window.smartlook('identify', shopName, { shopName });
            // } catch (e) {
            //     // to nothing
            //     // console.log('unable to set smartlook shopName', e);
            // }

            dispatch(setCreds(shopName, token));
            return dispatch(fetchData(shopName, token, appType))
        }
    }
}

export function pollForZoorixMInstalled(shopName) {
    return async (dispatch, getState) => {
        if (document.hasFocus()) {
            const res = await fetch(`https://is-m-enabled.zoorix.com/is-zoorix-M-app-blocks-enabled?sn=${shopName}`);
            if (res.ok) {
                const json = await res.json();
                dispatch(setPasswordEnabled(json.p));
                if (getState().data.zoorixMEnabled !== json.a) {
                    dispatch(setZoorixMInstalled(json.a));
                    // if (json.a) dispatch(setEnableZoorixModalOpen(false));
                }
            } else {
                console.log('pollForZoorixMInstalled failed', res)
            }
        }
        setTimeout(() => dispatch(pollForZoorixMInstalled(shopName)), 5000);
    }
}

export const closeWizard = (callback) => async (dispatch, getState) => {
    const res = await postPayload('data/hide_wizard', {}, getState());
    if (res.ok) {
        if (callback) callback();
    } else {
        console.log('closeWizard failed')
    }
};

export const nameChange = text => ({
    type: NAME_CHANGE,
    text
});

export const emailChange = text => ({
    type: EMAIL_CHANGE,
    text
});

export const updateZoorixToast = installUrl => ({
    type: UPDATE_ZOORIX_TOAST,
    installUrl,
});

export const dismissOutOfDateToast = () => ({
    type: DISMISS_OUT_OF_DATE_TOAST,
});


export const postPayload = async (path, payload, state) => {
    if (state) {
        payload.shop_name = state.data.shopName;
        payload.token = state.data.token;
    }
    return fetch(`https://${host}/${path}`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });
}

export const postDevPayload = async (path, payload, state) => {
    if (state) {
        payload.shop_name = 'woki-dev-store.myshopify.com';
        payload.token = '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';
    }
    return fetch(`https://${devHost}/${path}`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    });
}

export function checkIfClientIsUpToDate(shopName, token) {
    return (dispatch, getState) => {
        return fetch(`https://${host}/data/client_up_to_date`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    shop_name: shopName,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        let disable_install_redirect = false;
                        try {
                            disable_install_redirect = localStorage.getItem('disable_install_redirect');
                        } catch (e) {
                            console.log('unable to fetch disable_install_redirect', e);
                        }

                        if (json.install_url && !disable_install_redirect) {
                            dispatch(updateZoorixToast(json.install_url));
                        }
                    });
                } else {
                    console.log('checkIfClientIsUpToDate failed');
                }
            });

    }
}

export function updateZoorix() {
    return (dispatch, getState) => {
        const state = getState();
        window.open(state.data.installUrl, '_parent');
    }
}

