import {applyMiddleware, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import rootReducer from './reducers'
import fetch from "cross-fetch";
import installationStatusMiddleware from "./installationStatusMiddleware";
import {CLOSE_PAYMENT_MODAL} from "./actions/status";

const loggerMiddleware = createLogger();

const TRACKED_ACTIONS = [
    CLOSE_PAYMENT_MODAL,
];

function track({ getState }) {
    return next => action => {
        const state = getState();
        if (state.data && state.data.shopName && TRACKED_ACTIONS.includes(action.type)) {
            fetch(`https://lambda.zoorix.com/admin-track?shop_name=${state.data.shopName}&action_type=${action.type}`)
                .then(() => {});
        }
        return next(action);
    }
}

export default function configureStore(preloadedState) {
    const middleware = process.env.NODE_ENV === 'development' ?
        applyMiddleware(thunkMiddleware, track, loggerMiddleware, installationStatusMiddleware()) :
        applyMiddleware(thunkMiddleware, track, installationStatusMiddleware());
    return createStore(
        rootReducer,
        preloadedState,
        middleware
    )
}

