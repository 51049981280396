import fetch from "cross-fetch";
import {host} from "./data";

export const PRE_INIT_REDEEM = 'PRE_INIT_REDEEM';
export const INIT_REDEEM = 'INIT_REDEEM';
export const INIT_REDEEM_FAILURE = 'INIT_REDEEM_FAILURE';
export const CLOSE_REDEEM_MODAL = 'CLOSE_REDEEM_MODAL';
export const REDEEM_IMAGE_UPLOAD_SUCCESS = 'REDEEM_IMAGE_UPLOAD_SUCCESS';
export const REDEEM_IMAGE_UPLOAD_FAILURE = 'REDEEM_IMAGE_UPLOAD_FAILURE';
export const REDEEM_ACTION_START = 'REDEEM_ACTION_START';
export const REDEEM_NO_IMAGE = 'REDEEM_NO_IMAGE';
export const REDEEM_ACTION_SUCCESS = 'REDEEM_ACTION_SUCCESS';
export const REDEEM_ACTION_FAILURE = 'REDEEM_ACTION_FAILURE';
export const REDEEM_REQUEST_BODY_CHANGE = 'REDEEM_REQUEST_BODY_CHANGE';
export const REDEEM_ITEM = 'REDEEM_ITEM';

export const preInitRedeem = () => ({
    type: PRE_INIT_REDEEM,
});

export const initRedeem = json => ({
    type: INIT_REDEEM,
    lineItems: json.line_items,
    currency: json.currency,
});

export const initRedeemFailure = () => ({
    type: INIT_REDEEM_FAILURE,
});

export const closeRedeemModal = () => ({
    type: CLOSE_REDEEM_MODAL,
});

export const redeemImageUploadSuccess = () => ({
    type: REDEEM_IMAGE_UPLOAD_SUCCESS,
});

export const redeemImageUploadFailure = () => ({
    type: REDEEM_IMAGE_UPLOAD_FAILURE,
});

export const redeemActionStart = () => ({
    type: REDEEM_ACTION_START,
});

export const redeemNoImage = () => ({
    type: REDEEM_NO_IMAGE,
});

export const redeemActionSuccess = () => ({
    type: REDEEM_ACTION_SUCCESS,
});

export const redeemActionFailure = () => ({
    type: REDEEM_ACTION_FAILURE,
});

export const redeemRequestBodyChange = text => ({
    type: REDEEM_REQUEST_BODY_CHANGE,
    text,
});

export const redeemItem = id => ({
    type: REDEEM_ITEM,
    id,
});

export function initRedeemData(token) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.redeem.initialized) return;
        dispatch(preInitRedeem());
        return fetch(`https://${host}/redeem/init_data?token=${token}`)
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initRedeem(json));
                    });
                } else {
                    dispatch(initRedeemFailure());
                }
            });

    }
}

export function redeemAction(token) {
    return (dispatch, getState) => {
        const state = getState();
        if (!state.redeem.showUploadedImage) {
            dispatch(redeemNoImage());
            return;
        }
        dispatch(redeemActionStart());
        return fetch(`https://${host}/redeem/submit`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    item_id: state.redeem.itemId,
                    has_image: state.redeem.showUploadedImage,
                    request_body: state.redeem.requestBody,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initRedeem(json));
                        dispatch(closeRedeemModal());
                        dispatch(redeemActionSuccess());
                    });
                } else {
                    dispatch(redeemActionFailure());
                }
            });

    }
}
