// https://www.npmjs.com/package/amazon-cognito-identity-js
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
// import * as AWS from 'aws-sdk/global';
import fetch from "cross-fetch";
import {host} from "./data";

const poolData = {
    UserPoolId: 'us-east-2_NggbuIauV', // Your user pool id here
    ClientId: '7o2hnifff0la2uqtl7r1m9gajv', // Your client id here
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export const PARTNERS_EMAIL_CHANGED = 'PARTNERS_EMAIL_CHANGED';
export const PARTNERS_PASSWORD_CHANGED = 'PARTNERS_PASSWORD_CHANGED';
export const PARTNERS_VERIFICATION_CODE_CHANGE = 'PARTNERS_VERIFICATION_CODE_CHANGE';
export const PARTNERS_OLD_PASSWORD_CHANGE = 'PARTNERS_OLD_PASSWORD_CHANGE';
export const PARTNERS_NEW_PASSWORD_CHANGE = 'PARTNERS_NEW_PASSWORD_CHANGE';
export const PARTNERS_PAYPAL_EMAIL_CHANGE = 'PARTNERS_PAYPAL_EMAIL_CHANGE';
export const PARTNERS_OFFERING_VALUE_CHANGE = 'PARTNERS_OFFERING_VALUE_CHANGE';
export const PARTNERS_SET_BRAND = 'PARTNERS_SET_BRAND';
export const PARTNERS_TOAST_STATUS = 'PARTNERS_TOAST_STATUS';
export const PARTNERS_SET_SAVED_BRAND = 'PARTNERS_SET_SAVED_BRAND';
export const PARTNERS_LOGGING_IN = 'PARTNERS_LOGGING_IN';
export const PARTNERS_SENDING_FORGOT_PASSWORD_CODE = 'PARTNERS_SENDING_FORGOT_PASSWORD_CODE';
export const PARTNERS_NAV_TO_SIGN_UP = 'PARTNERS_NAV_TO_SIGN_UP';
export const PARTNERS_NAV_TO_LOGIN = 'PARTNERS_NAV_TO_LOGIN';
export const PARTNERS_NAV_TO_FORGOT_PASSWORD = 'PARTNERS_NAV_TO_FORGOT_PASSWORD';
export const PARTNERS_NAV_TO_DASHBOARD = 'PARTNERS_NAV_TO_DASHBOARD';
export const PARTNERS_NAV_TO_VERIFY_FORGOT_PASSWORD_CODE = 'PARTNERS_NAV_TO_VERIFY_FORGOT_PASSWORD_CODE';
export const PARTNERS_DISMISS_TOAST = 'PARTNERS_DISMISS_TOAST';
export const PARTNERS_CHANGE_PASSWORD_SUCCESS = 'PARTNERS_CHANGE_PASSWORD_SUCCESS';
export const PARTNERS_COPY_REF_URL_SUCCESS = 'PARTNERS_COPY_REF_URL_SUCCESS';
export const PARTNERS_COPY_REF_URL_FAILURE = 'PARTNERS_COPY_REF_URL_FAILURE';
export const PARTNERS_OPEN_CHANGE_PASSWORD_MODAL = 'PARTNERS_OPEN_CHANGE_PASSWORD_MODAL';
export const PARTNERS_CHANGING_PASSWORD = 'PARTNERS_CHANGING_PASSWORD';
export const PARTNERS_UPDATING_PAYPAL_EMAIL = 'PARTNERS_UPDATING_PAYPAL_EMAIL';
export const PARTNERS_SET_BRAND_AVAILABLE = 'PARTNERS_SET_BRAND_AVAILABLE';
export const PARTNERS_SET_BRAND_TOO_SHORT = 'PARTNERS_SET_BRAND_TOO_SHORT';
export const PARTNERS_CHECKING_BRAND_AVAILABILITY = 'PARTNERS_CHECKING_BRAND_AVAILABILITY';
export const PARTNERS_SAVING_BRAND = 'PARTNERS_SAVING_BRAND';
export const PARTNERS_UPDATE_PAYPAL_EMAIL_COMPLETE = 'PARTNERS_UPDATE_PAYPAL_EMAIL_COMPLETE';
export const PARTNERS_NAV_TO_VERIFY_CODE = 'PARTNERS_NAV_TO_VERIFY_CODE';
export const SET_PARTNER = 'SET_PARTNER';
export const PARTNERS_SIGNING_UP = 'PARTNERS_SIGNING_UP';
export const PARTNERS_VERIFYING_CODE = 'PARTNERS_VERIFYING_CODE';
export const PARTNERS_TRIED_TO_LOGIN = 'PARTNERS_TRIED_TO_LOGIN';
export const ZOORIX_REF_CODE = 'zoorix_ref_code';

export const partnersEmailChanged = text => ({
    type: PARTNERS_EMAIL_CHANGED,
    text,
});

export const partnersPasswordChanged = text => ({
    type: PARTNERS_PASSWORD_CHANGED,
    text,
});

export const partnersVerificationCodeChange = text => ({
    type: PARTNERS_VERIFICATION_CODE_CHANGE,
    text,
});

export const partnersOldPasswordChange = text => ({
    type: PARTNERS_OLD_PASSWORD_CHANGE,
    text,
});

export const partnersNewPasswordChange = text => ({
    type: PARTNERS_NEW_PASSWORD_CHANGE,
    text,
});

export const partnersPaypalEmailChange = text => ({
    type: PARTNERS_PAYPAL_EMAIL_CHANGE,
    text,
});

export const partnersOfferingValueChange = text => ({
    type: PARTNERS_OFFERING_VALUE_CHANGE,
    text,
});

export const partnersSetBrand = text => ({
    type: PARTNERS_SET_BRAND,
    text,
});

export const partnersToastStatus = text => ({
    type: PARTNERS_TOAST_STATUS,
    text,
});

export const partnersSetSavedBrand = text => ({
    type: PARTNERS_SET_SAVED_BRAND,
    text,
});

export const partnersLoggingIn = bool => ({
    type: PARTNERS_LOGGING_IN,
    bool,
});

export const partnersSendingForgotPasswordCode = bool => ({
    type: PARTNERS_SENDING_FORGOT_PASSWORD_CODE,
    bool,
});

export const partnersSigningUp = bool => ({
    type: PARTNERS_SIGNING_UP,
    bool,
});

export const partnersVerifyingCode = bool => ({
    type: PARTNERS_VERIFYING_CODE,
    bool,
});

export const partnersNavToSignUp = () => ({
    type: PARTNERS_NAV_TO_SIGN_UP,
});

export const partnersTriedToLogin = () => ({
    type: PARTNERS_TRIED_TO_LOGIN,
});

export const partnersNavToLogin = () => ({
    type: PARTNERS_NAV_TO_LOGIN,
});

export const partnersNavToForgotPassword = () => ({
    type: PARTNERS_NAV_TO_FORGOT_PASSWORD,
});

export const partnersNavToDashboard = () => ({
    type: PARTNERS_NAV_TO_DASHBOARD,
});

export const partnersNavToVerifyForgotPasswordCode = () => ({
    type: PARTNERS_NAV_TO_VERIFY_FORGOT_PASSWORD_CODE,
});

export const partnersDismissToast = () => ({
    type: PARTNERS_DISMISS_TOAST,
});

export const partnersChangePasswordSuccess = () => ({
    type: PARTNERS_CHANGE_PASSWORD_SUCCESS,
});

export const partnersCopyRefUrlSuccess = () => ({
    type: PARTNERS_COPY_REF_URL_SUCCESS,
});

export const partnersCopyRefUrlFailure = () => ({
    type: PARTNERS_COPY_REF_URL_FAILURE,
});

export const partnersOpenChangePasswordModal = bool => ({
    type: PARTNERS_OPEN_CHANGE_PASSWORD_MODAL,
    bool,
});

export const partnersChangingPassword = bool => ({
    type: PARTNERS_CHANGING_PASSWORD,
    bool,
});

export const partnersUpdatingPaypalEmail = bool => ({
    type: PARTNERS_UPDATING_PAYPAL_EMAIL,
    bool,
});

export const partnersSetBrandAvailable = bool => ({
    type: PARTNERS_SET_BRAND_AVAILABLE,
    bool,
});

export const partnersSetBrandTooShort = bool => ({
    type: PARTNERS_SET_BRAND_TOO_SHORT,
    bool,
});

export const partnersCheckingBrandAvailability = bool => ({
    type: PARTNERS_CHECKING_BRAND_AVAILABILITY,
    bool,
});

export const partnersSavingBrand = bool => ({
    type: PARTNERS_SAVING_BRAND,
    bool,
});

export const partnersUpdatePaypalEmailComplete = result => ({
    type: PARTNERS_UPDATE_PAYPAL_EMAIL_COMPLETE,
    result,
});

export const partnersNavToVerifyCode = username => ({
    type: PARTNERS_NAV_TO_VERIFY_CODE,
    username,
});

export const setPartner = json => ({
    type: SET_PARTNER,
    sub: json.sub,
    secret: json.secret,
    brand: json.brand,
    paypalEmail: json.paypal_email,
    offerings: json.offerings,
    tinyCodes: json.tiny_codes,
    visits: json.visits,
    referrals: json.referrals,
    totalEarnings: json.total_earnings,
    totalPaid: json.total_paid,
    referredStores: json.referred_stores,
    partnerPayments: json.partner_payments,
    package: json.package,
});

export function partnersLogin() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(partnersLoggingIn(true));
        const authenticationData = {
            Username: state.partners.email,
            Password: state.partners.password,
        };
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
            authenticationData
        );
        const userData = {
            Username: state.partners.email,
            Pool: userPool,
        };
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function(result) {
                dispatch(partnersLoggingIn(false));
                dispatch(partnersNavToDashboard());
                dispatch(findOrCreatePartner(result.getIdToken().getJwtToken()));
            },

            onFailure: function(err) {
                alert(err.message || JSON.stringify(err));
                dispatch(partnersLoggingIn(false));
            },
        });
    }
}

export function partnersSaveCodeAndRedirect(code) {
    return (dispatch, getState) => {
        try {
            localStorage.setItem(ZOORIX_REF_CODE, code);
        } catch (e) {
            console.log('unable to set ref code', e)
        }

        fetch(`https://${host}/partners/inc_ref_visits?ref_code=${code}`)
            .then((response) => {
                window.open('https://apps.shopify.com/zoorix', '_self');
            })
    }
}

export const partnersSaveCodeBrandAndRedirect = (code, brand) => async (dispatch, getState) => {
    const res = await fetchPartnersApi('partners/get_code_from_tiny', undefined, {
        code,
        brand,
    })
    if (res.ok) {
        const json = await res.json();
        try {
            localStorage.setItem(ZOORIX_REF_CODE, json.code);
        } catch (e) {
            console.log('unable to set ref code', e)
        }
    }
    window.open('https://apps.shopify.com/zoorix', '_self');
};

export const fetchPartnersApi = (path, state, payload = {}) => {
    if (state) {
        payload.sub = state.partners.sub;
        payload.secret = state.partners.secret;
    }
    return fetch(`https://${host}/${path}`,
        {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
}

export const partnersBrandChange = text => async (dispatch, getState) => {
    dispatch(partnersSetBrand(text));
    if (!text) {
        dispatch(partnersSetBrandAvailable(true));
        dispatch(partnersSetBrandTooShort(false));
        dispatch(partnersCheckingBrandAvailability(false));
        return
    } else if (text.length < 3) {
        dispatch(partnersSetBrandAvailable(false));
        dispatch(partnersSetBrandTooShort(true));
        dispatch(partnersCheckingBrandAvailability(false));
        return
    }
    dispatch(partnersSetBrandTooShort(false));
    dispatch(partnersCheckingBrandAvailability(true));
    const state = getState();
    const res = await fetchPartnersApi('partners/brand_taken', state, {
        brand: text,
    });
    if (res.ok) {
        const json = await res.json();
        if (getState().partners.brand === text) {
            dispatch(partnersSetBrandAvailable(!json.taken));
            dispatch(partnersCheckingBrandAvailability(false));
        }
    } else {
        console.log('partnersBrandChange failed')
    }
}

export const partnersSaveBrand = () => async (dispatch, getState) => {
    dispatch(partnersSavingBrand(true));
    const state = getState();
    const brand = state.partners.brand;
    const res = await fetchPartnersApi('partners/set_brand', state, {
        brand: brand,
    });
    if (res.ok) {
        dispatch(partnersToastStatus('Brand saved'));
        dispatch(partnersSetSavedBrand(brand));
    } else {
        dispatch(partnersToastStatus('Failed to save brand'));
    }
    dispatch(partnersSavingBrand(false));
}

export function partnersSendForgotPasswordCode() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(partnersSendingForgotPasswordCode(true));
        const userData = {
            Username: state.partners.email,
            Pool: userPool,
        };
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.forgotPassword({
            onSuccess: function(data) {
                // successfully initiated reset password request
                console.log('CodeDeliveryData from forgotPassword: ' + data);
                dispatch(partnersSendingForgotPasswordCode(false));
                dispatch(partnersNavToVerifyForgotPasswordCode());
            },
            onFailure: function(err) {
                alert(err.message || JSON.stringify(err));
                dispatch(partnersSendingForgotPasswordCode(false));
            },
        });
    }
}

export function partnersLogout() {
    return (dispatch, getState) => {
        const cognitoUser = userPool.getCurrentUser();
        cognitoUser.signOut();
        dispatch(partnersNavToLogin());
    }
}

export function partnersChangePassword() {
    return (dispatch, getState) => {
        dispatch(partnersChangingPassword(true));
        const state = getState();
        const cognitoUser = userPool.getCurrentUser();
        cognitoUser.getSession(function (err, session) {
            if (err) {
                console.log(err.message || JSON.stringify(err));
                dispatch(partnersChangingPassword(false));
                return;
            }
            console.log('session validity: ' + session.isValid());
            if (session.isValid()) {
                cognitoUser.changePassword(state.partners.oldPassword, state.partners.newPassword, function(err, result) {
                    dispatch(partnersChangingPassword(false));
                    if (err) {
                        alert(err.message || JSON.stringify(err));
                        return;
                    }
                    dispatch(partnersOpenChangePasswordModal(false));
                    dispatch(partnersChangePasswordSuccess());
                    console.log('call result: ' + result);
                });
            } else {
                dispatch(partnersChangingPassword(false));
            }
        })
    }
}

function findOrCreatePartner(token) {
    return (dispatch, getState) => {
        fetch(`https://${host}/partners/create_or_find`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(setPartner(json)));
                } else {
                    console.log('findOrCreatePartner failed', response);
                    dispatch(partnersNavToLogin());
                }
            })
    }
}

export function partnersUpdatePaypalEmail() {
    return (dispatch, getState) => {
        dispatch(partnersUpdatingPaypalEmail(true));
        const state = getState();
        fetch(`https://${host}/partners/update_paypal_email`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sub: state.partners.sub,
                    secret: state.partners.secret,
                    paypal_email: state.partners.paypalEmail,
                })
            })
            .then((response) => {
                dispatch(partnersUpdatingPaypalEmail(false));
                if (response.ok) {
                    response.json().then(json => dispatch(partnersUpdatePaypalEmailComplete(json)));
                } else {
                    dispatch(partnersUpdatePaypalEmailComplete(false));
                }
            })
    }
}

export function partnersCopyRefUrl() {
    return (dispatch, getState) => {
        const state = getState();
        if (navigator.clipboard) {
            navigator.clipboard.writeText(state.partners.refUrl).then(() => {
                dispatch(partnersCopyRefUrlSuccess());
            }, err => {
                console.log(err);
                dispatch(partnersCopyRefUrlFailure());
            })
        } else {
            const copyText = document.getElementById("refUrl");

            /* Select the text field */
            copyText.focus();
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            console.log(document.execCommand("copy"));
            dispatch(partnersCopyRefUrlSuccess());
        }
    }
}

export function partnersTryToLogin() {
    return (dispatch, getState) => {
        const state = getState();
        if (state.partners.triedToLogin) return;
        dispatch(partnersTriedToLogin());
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log(err.message || JSON.stringify(err))
                    dispatch(partnersNavToLogin());
                    return;
                }
                console.log('session validity: ' + session.isValid());
                if (session.isValid()) {
                    dispatch(partnersNavToDashboard());
                    const token = session.getIdToken().getJwtToken();
                    dispatch(findOrCreatePartner(token));
                } else {
                    dispatch(partnersNavToLogin());
                }
            });
        } else {
            dispatch(partnersNavToLogin());
        }
    }
}

export function partnersAdminLogin(sub, secret) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.partners.triedToLogin) return;
        dispatch(partnersTriedToLogin());
        fetch(`https://${host}/partners/login_admin`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sub: sub,
                    secret: secret,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then(json => dispatch(setPartner(json)));
                } else {
                    console.log('partnersAdminLogin failed', response);
                }
            })

    }
}

export function partnersSignUp() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(partnersSigningUp(true));
        userPool.signUp(state.partners.email, state.partners.password, [], null, function(
            err,
            result
        ) {
            dispatch(partnersSigningUp(false));
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            const cognitoUser = result.user;
            // console.log('user name is ' + cognitoUser.getUsername());
            dispatch(partnersNavToVerifyCode(cognitoUser.getUsername()));
        });
    }
}

export function partnersVerifyCode() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(partnersVerifyingCode(true));
        const userData = {
            Username: state.partners.username,
            Pool: userPool,
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmRegistration(state.partners.verificationCode, true, function(err, result) {
            dispatch(partnersVerifyingCode(false));
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ', result);
            dispatch(partnersLogin());
        });
    }
}

export function partnersSetNewPassword() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(partnersVerifyingCode(true));
        const userData = {
            Username: state.partners.email,
            Pool: userPool,
        };
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.confirmPassword(state.partners.verificationCode, state.partners.password, {
            onSuccess() {
                console.log('Password confirmed!');
                dispatch(partnersVerifyingCode(false));
                dispatch(partnersLogin());
            },
            onFailure(err) {
                console.log('Password not confirmed!');
                alert(err.message || JSON.stringify(err));
                dispatch(partnersVerifyingCode(false));
            },
        });

    }
}
