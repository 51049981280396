import { connect } from 'react-redux'
import Excluded from "../../components/products/Excluded";
import {
    addAllToExcludedByFilter,
    addAnotherExcludedFilterChange,
    backToMain,
    changeExcludedSearchText,
    chooseExcludedProduct,
    dynamicGroupingChange, excludedFilter2Change, excludedFilter2OptionChange,
    excludedFilterChange,
    excludedFilterOptionChange,
    removeExcludedItem,
    saveExcluded,
    toggleAddAllToExcludedByFilterActive,
} from "../../actions/products";

const mapStateToProps = state => ({
    showSearchSpinner: state.products.showSearchSpinner,
    searchBoxText: state.products.searchBoxText,
    searchedProducts: state.products.searchedProducts,
    searchError: state.products.searchError,
    excludedProducts: state.products.excludedProducts,
    isSavingExcluded: state.products.isSavingExcluded,
    saveError: state.products.saveError,
    addAllToExcludedByFilterActive: state.products.addAllToExcludedByFilterActive,
    isDynamicGrouping: state.products.isDynamicGrouping,
    excludedFilter: state.products.excludedFilter,
    excludedFilterOption: state.products.excludedFilterOption,
    excludedFilterOptions: state.products.excludedFilterOptions,
    isAddAnotherExcludedFilter: state.products.isAddAnotherExcludedFilter,
    excludedFilter2: state.products.excludedFilter2,
    excludedFilterOptions2: state.products.excludedFilterOptions2,
    excludedFilterOption2: state.products.excludedFilterOption2,
    isAddingAllToExcludedByFilter: state.products.isAddingAllToExcludedByFilter,
});

const mapDispatchToProps = (dispatch) => ({
    backToMain: () => dispatch(backToMain()),
    onChangeExcludedSearchText: text => dispatch(changeExcludedSearchText(text)),
    onFocusInExcludedSearchText: () => dispatch(changeExcludedSearchText('')),
    addExcludedItem: id => () => dispatch(chooseExcludedProduct(id)),
    removeExcludedItem: id => () => dispatch(removeExcludedItem(id)),
    onSaveExcluded: () => dispatch(saveExcluded()),
    toggleAddAllToExcludedByFilterActive: () => dispatch(toggleAddAllToExcludedByFilterActive()),
    onDynamicGroupingChange: bool => dispatch(dynamicGroupingChange(bool)),
    onExcludedFilterChange: text => dispatch(excludedFilterChange(text)),
    onExcludedFilterOptionChange: text => dispatch(excludedFilterOptionChange(text)),
    onAddAnotherExcludedFilterChange: bool => dispatch(addAnotherExcludedFilterChange(bool)),
    onExcludedFilterChange2: text => dispatch(excludedFilter2Change(text)),
    onExcludedFilter2OptionChange: text => dispatch(excludedFilter2OptionChange(text)),
    onAddAllToExcludedByFilter: () => dispatch(addAllToExcludedByFilter()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Excluded);
