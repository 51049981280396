import fetch from "cross-fetch";
import {host, ZOORIX_SHOPIFY_DOMAIN} from "./data";
import {dismissFeedbackPopup} from "./status";

export const POST_SUBMIT_RATING = 'POST_SUBMIT_RATING';
export const INIT_FEEDBACK = 'INIT_FEEDBACK';
export const INIT_FEEDBACK_TOKEN = 'INIT_FEEDBACK_TOKEN';
export const RATING_TEXT_CHANGE = 'RATING_TEXT_CHANGE';
export const RATING_SUBMIT_SUCCESS = 'RATING_SUBMIT_SUCCESS';
export const RATING_SUBMIT_ERROR = 'RATING_SUBMIT_ERROR';

export const initFeedback = (token, rating) => ({
    type: INIT_FEEDBACK,
    token,
    rating,
});

export const initFeedbackToken = token => ({
    type: INIT_FEEDBACK_TOKEN,
    token,
});

export const ratingTextChange = (text) => ({
    type: RATING_TEXT_CHANGE,
    text,
});

const postSubmitRating = () => ({
    type: POST_SUBMIT_RATING,
});

const ratingSubmitSuccess = () => ({
    type: RATING_SUBMIT_SUCCESS,
});

const ratingSubmitError = () => ({
    type: RATING_SUBMIT_ERROR,
});

export function feedbackLandingPageRate(rating) {
    return (dispatch, getState) => {
        const state = getState();
        window.open(`/feedback/${state.feedback.token}/${rating}`, '_self');
    }
}

export function initFeedbackIfNeeded(token, rating) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.feedback.rating) return;
        dispatch(initFeedback(token, rating));
    }
}

export function openFeedback(rating) {
    return (dispatch, getState) => {
        const state = getState();
        window.open(`/feedback/${state.data.token_id}/${rating}`, '_blank');
        dispatch(dismissFeedbackPopup());
    }
}

export function submitFiveStars() {
    return (dispatch, getState) => {
        //const state = getState();
        dispatch(submitRating());
    }
}

export function submitRating() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(postSubmitRating());
        return fetch(`https://${host}/rating/submit`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: state.feedback.token,
                    rating: state.feedback.rating,
                    rating_text: state.feedback.ratingText,
                })
            })
            .then((response) => {
                if (state.feedback.rating === '5') {
                    window.open('https://apps.shopify.com/zoorix#modal-show=ReviewListingModal', '_self');
                } else if (response.ok) {
                    dispatch(ratingSubmitSuccess());
                } else {
                    dispatch(ratingSubmitError())
                }
            });

    }
}

export function submitSupportRating() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(postSubmitRating());
        let shop_name = null;
        try {
            shop_name = localStorage.getItem(ZOORIX_SHOPIFY_DOMAIN)
        } catch (e) {
            console.log('unable to fetch shop name', e);
        }
        return fetch(`https://${host}/rating/support`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: shop_name,
                    rating: state.feedback.rating,
                    review: state.feedback.ratingText,
                })
            })
            .then((response) => {
                if (state.feedback.rating === '5') {
                    window.open('https://apps.shopify.com/zoorix#modal-show=ReviewListingModal', '_self');
                } else if (response.ok) {
                    dispatch(ratingSubmitSuccess());
                } else {
                    dispatch(ratingSubmitError())
                }
            });

    }
}
