import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";

import {
    CHANGE_MNM_PREVIEW_MODE,
    INIT_MIX_AND_MATCH,
    MIX_AND_MATCH_ADD_PRODUCT,
    MIX_AND_MATCH_ADD_PRODUCT_GROUP,
    MIX_AND_MATCH_ADD_TIER,
    MIX_AND_MATCH_BOOL_CHANGE,
    MIX_AND_MATCH_CREATE_OFFER,
    MIX_AND_MATCH_CREATING_OFFER,
    MIX_AND_MATCH_DELETE_PRODUCT_GROUP,
    MIX_AND_MATCH_DELETE_TIER,
    MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP,
    MIX_AND_MATCH_DELETING_OFFER,
    MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE,
    MIX_AND_MATCH_DISCOUNT_TITLE_CHANGE,
    MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE,
    MIX_AND_MATCH_DISMISS_TOAST,
    MIX_AND_MATCH_EDIT_OFFER,
    MIX_AND_MATCH_EDIT_PRODUCT_GROUP,
    MIX_AND_MATCH_LIMIT_NUM_ITEMS_CHANGE,
    MIX_AND_MATCH_LOAD_OFFERS,
    MIX_AND_MATCH_LOAD_OFFERS_ERROR,
    MIX_AND_MATCH_LOCALE_CHANGE,
    MIX_AND_MATCH_MAX_QTY_FOR_BUYING_CHANGE,
    MIX_AND_MATCH_MIN_QTY_CHANGE,
    MIX_AND_MATCH_MIN_QTY_FOR_BUYING_CHANGE,
    MIX_AND_MATCH_NAV_TO_CHANGE,
    MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE,
    MIX_AND_MATCH_OFFER_NAME_CHANGE,
    MIX_AND_MATCH_OFFER_TEXT_CHANGE,
    MIX_AND_MATCH_OPEN_ADD_PRODUCT_GROUP_MODAL,
    MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL, MIX_AND_MATCH_OPEN_PARENT_PRODUCT_MODAL,
    MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL,
    MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL,
    MIX_AND_MATCH_PREVIEW_OFFER_LOADING,
    MIX_AND_MATCH_PRODUCT_GROUP_STRING_CHANGE, MIX_AND_MATCH_QUANTITY_MODIFIER_CHANGE,
    MIX_AND_MATCH_REMOVE_PRODUCT,
    MIX_AND_MATCH_SAVING_OFFER,
    MIX_AND_MATCH_SAVING_PROMO_TITLE,
    MIX_AND_MATCH_SAVING_SETTINGS,
    MIX_AND_MATCH_SEARCHING_COLLECTION,
    MIX_AND_MATCH_SEARCHING_PRODUCT,
    MIX_AND_MATCH_SELECT_COLLECTION, MIX_AND_MATCH_SELECT_PARENT_PRODUCT, MIX_AND_MATCH_SET_PARENT_PRODUCT,
    MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE, MIX_AND_MATCH_SET_SEARCH_PARENT_PRODUCT_TITLE,
    MIX_AND_MATCH_SET_SEARCH_PRODUCT_TITLE,
    MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS, MIX_AND_MATCH_SET_SEARCHED_PARENT_PRODUCTS,
    MIX_AND_MATCH_SET_SEARCHED_PRODUCTS,
    MIX_AND_MATCH_SET_STATE,
    MIX_AND_MATCH_SETTING_COLOR_CHANGE,
    MIX_AND_MATCH_SETTINGS_BOOL_CHANGE,
    MIX_AND_MATCH_SETTINGS_TEXT_CHANGE,
    MIX_AND_MATCH_STRING_CHANGE,
    MIX_AND_MATCH_TIER_DISCOUNT_AMOUNT_CHANGE, MIX_AND_MATCH_TIER_DISCOUNT_TYPE_CHANGE,
    MIX_AND_MATCH_TIER_MIN_QTY_CHANGE,
    MIX_AND_MATCH_TOAST,
    MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL,
    MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE,
    MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE,
    MNM_DUPLICATING_OFFER,
    MNM_MOVING_OFFER_DOWN,
    MNM_MOVING_OFFER_UP,
    SWITCHING_MNM_PREVIEW
} from "../actions/mixAndMatch";
import {calcLocales} from "./texts";

export function fillDefault(state, attr) {
    if (!state.settings.values[attr]) {
        state.settings.values[attr] = state.settings.defaults[attr];
    }
}

const methodToText = {
    legacy: 'Legacy Discount Code',
    product_discount: 'Functions Product Discount',
    cart_transform: 'Functions Cart Transform'
};

const mixAndMatch = (state = { }, action) => {
    let newState = null;
    switch (action.type) {
        case INIT_MIX_AND_MATCH:
            newState = _cloneDeep(state);
            newState.settings = _cloneDeep(action.json.mix_and_match_settings);
            ['go_to_bundle_builder_background_color', 'go_to_bundle_builder_text_color', 'bundle_builder_save_text_color',
                'bundle_builder_save_bg_color', 'original_price_text_color', 'discounted_price_text_color',
                'num_of_searched_products', 'mix_and_match_promo_page_title'].forEach(
                    attr => fillDefault(newState, attr));
            newState.methodOptions = Object.entries(newState.settings.methods).map(entry => ({label: methodToText[entry[0]], value: entry[1]}));

            // if (!action.eligibilityJson?.bundle_eligibility?.data?.shop?.features?.bundles?.eligibleForBundles) {
            //     newState.methodOptions = newState.methodOptions.filter(o => o.value !== "cart_transform_api");
            // }

            // deprecating cart_transform_api:
            newState.methodOptions = newState.methodOptions.filter(o => o.value !== "cart_transform_api");
            break;
        case MIX_AND_MATCH_LOAD_OFFERS:
            newState = _cloneDeep(state);
            newState.offers = _cloneDeep(action.json.offers);
            newState.promoPage = _cloneDeep(action.json.promo_page);
            newState.duplicateOfferStarted = {};
            newState.movingOfferDown = {};
            newState.movingOfferUp = {};
            newState.loadOffersError = false;
            newState.offersReady = true;
            newState.previewOfferLoading = {};
            newState.switchingPreview = {};
            break;
        case MIX_AND_MATCH_SET_SEARCHED_COLLECTIONS:
            newState = _cloneDeep(state);
            newState.searchedCollections = _cloneDeep(action.json.result.data.collections.edges);
            break;
        case MIX_AND_MATCH_SET_SEARCHED_PRODUCTS:
            newState = _cloneDeep(state);
            newState.searchedProducts = _cloneDeep(action.json.result.data.data.products.edges);
            break;
        case MIX_AND_MATCH_SET_SEARCHED_PARENT_PRODUCTS:
            newState = _cloneDeep(state);
            newState.searchedParentProducts = _cloneDeep(action.json.result.data.data.products.edges);
            break;
        case MIX_AND_MATCH_SET_PARENT_PRODUCT:
            newState = _cloneDeep(state);
            newState.parentProduct = _cloneDeep(action.json.result);
            break;
        case MIX_AND_MATCH_EDIT_OFFER:
            newState = _cloneDeep(state);
            newState.offer = _cloneDeep(newState.offers.find(o => o.id === action.id));
            newState.offersState = 'edit_offer';
            newState.discountTypes = newState.offer.offer_type === 'cart_transform' ?
              [{label: 'Percentage', value: 'percentage'}] :
              newState.offer.offer_type === 'functions' ?
                [{label: 'Percentage', value: 'percentage'}, {label: 'Fixed', value: 'fixed'}, {label: 'Tiered', value: 'tiered'}] :
                [{label: 'Percentage', value: 'percentage'}, {label: 'Fixed', value: 'fixed'}];
            calcLocales(newState, newState.offer.texts);
            break;
        case MIX_AND_MATCH_OPEN_DELETE_OFFER_MODAL:
            newState = _cloneDeep(state);
            newState.deleteOfferModalOpen = action.bool;
            newState.offerToDelete = action.id;
            break;
        case MIX_AND_MATCH_QUANTITY_MODIFIER_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.quantity_modifier = action.text === 'yes';
            break;
        case MIX_AND_MATCH_OPEN_SHORT_CODE_MODAL:
            newState = _cloneDeep(state);
            newState.shortCodeModalOpen = action.bool;
            newState.shortCodeOfferId = action.id;
            newState.shortCode = `<span zoorix-widget="mix-match" zoorix-widget-type="collapsed" zoorix-offer-id="${action.id}"></span>`;
            break;
        case MIX_AND_MATCH_PREVIEW_OFFER_LOADING:
            newState = _cloneDeep(state);
            newState.previewOfferLoading[action.id] = action.bool;
            break;
        case SWITCHING_MNM_PREVIEW:
            newState = _cloneDeep(state);
            newState.switchingPreview[action.id] = action.bool;
            break;
        case MNM_DUPLICATING_OFFER:
            newState = _cloneDeep(state);
            newState.duplicateOfferStarted[action.id] = action.bool;
            break;
        case MNM_MOVING_OFFER_DOWN:
            newState = _cloneDeep(state);
            newState.movingOfferDown[action.id] = action.bool;
            break;
        case MNM_MOVING_OFFER_UP:
            newState = _cloneDeep(state);
            newState.movingOfferUp[action.id] = action.bool;
            break;
        case CHANGE_MNM_PREVIEW_MODE:
            newState = _cloneDeep(state);
            {
                const offer = newState.offers.find(o => o.id === action.id);
                offer.preview_mode = action.bool;
            }
            break;
        case MIX_AND_MATCH_CREATE_OFFER:
            newState = _cloneDeep(state);
            if (action.json.available_locales) newState.availableLocales = _cloneDeep(action.json.available_locales);
            newState.parentProduct = {};
            newState.offer = {
                enabled: true,
                quantity_modifier: true,
                offer_type: action.json.offer_type,
                name: action.json.name,
                texts: _cloneDeep(action.json.translations),
                offer: {
                    discount: {
                        type: 'percentage',
                        amount: 0,
                    },
                    product_groups: [],
                },
                navigate_to: 'checkout',
                triggers: {
                    on_offer_items: true,
                    product_groups: [],
                },
                trigger_locations: {
                    product_main: true,
                    product_bottom: false,
                    cart_top: false,
                    cart_bottom: false,
                }
            }
            newState.discountTypes = action.json.offer_type === 'cart_transform' ?
              [{label: 'Percentage', value: 'percentage'}] :
              action.json.offer_type === 'functions' ?
              [{label: 'Percentage', value: 'percentage'}, {label: 'Fixed', value: 'fixed'}, {label: 'Tiered', value: 'tiered'}] :
              [{label: 'Percentage', value: 'percentage'}, {label: 'Fixed', value: 'fixed'}];
            calcLocales(newState, newState.offer.texts);
            break;
        case MIX_AND_MATCH_SAVING_SETTINGS:
            newState = _cloneDeep(state);
            newState.saveInProgress = action.bool;
            break;
        case MIX_AND_MATCH_SAVING_PROMO_TITLE:
            newState = _cloneDeep(state);
            newState.savingPromoPageTitle = action.bool;
            break;
        case MIX_AND_MATCH_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = true;
            newState.toastContent = action.text;
            break;
        case MIX_AND_MATCH_NUM_OF_SEARCHED_PRODUCTS_CHANGE:
            newState = _cloneDeep(state);
            if (action.text === '') {
                newState.settings.values.num_of_searched_products = action.text;
            } else {
                const i = parseInt(action.text);
                if (i < 1) {
                    newState.settings.values.num_of_searched_products = 1;
                } else if (i > 12) {
                    newState.settings.values.num_of_searched_products = 12;
                } else {
                    newState.settings.values.num_of_searched_products = i;
                }
            }
            break;
        case MIX_AND_MATCH_SET_SEARCH_COLLECTION_TITLE:
            newState = _cloneDeep(state);
            newState.searchCollectionTitle = action.text;
            break;
        case MIX_AND_MATCH_SET_SEARCH_PRODUCT_TITLE:
            newState = _cloneDeep(state);
            newState.searchedProductTitle = action.text;
            break;
        case MIX_AND_MATCH_SET_SEARCH_PARENT_PRODUCT_TITLE:
            newState = _cloneDeep(state);
            newState.searchedParentProductTitle = action.text;
            break;
        case MIX_AND_MATCH_SELECT_PARENT_PRODUCT:
            newState = _cloneDeep(state);
            newState.offer.parent_product_id = action.node.id.match(/gid:\/\/shopify\/Product\/(.*)/)[1];
            newState.parentProduct = {
                title: action.node.title,
                image: { src: action.node.images?.edges[0]?.node?.originalSrc },
            };
            break;
        case MIX_AND_MATCH_OFFER_NAME_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.name = action.text;
            break;
        case MIX_AND_MATCH_DISCOUNT_TITLE_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.discount_title = action.text;
            break;
        case MIX_AND_MATCH_DISCOUNT_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.type = action.text;
            break;
        case MIX_AND_MATCH_DISCOUNT_AMOUNT_CHANGE:
            newState = _cloneDeep(state);
            if (action.text < 0) action.text = 0;
            newState.offer.offer.discount.amount = parseFloat(action.text);
            break;
        case MIX_AND_MATCH_NAV_TO_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.navigate_to = action.text;
            break;
        case MIX_AND_MATCH_STRING_CHANGE:
            newState = _cloneDeep(state);
            newState.offer[action.attr] = action.text;
            break;
        case MIX_AND_MATCH_TRIGGER_DISPLAY_OPTIONS_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.triggers.on_offer_items = action.text === 'offer_items';
            break;
        case MIX_AND_MATCH_LOCALE_CHANGE:
            newState = _cloneDeep(state);
            newState.locale = action.locale;
            break;
        case MIX_AND_MATCH_DISMISS_TOAST:
            newState = _cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case MIX_AND_MATCH_TOGGLE_SEARCH_COLLECTIONS_MODAL:
            newState = _cloneDeep(state);
            newState.selectCollectionsModalOpen = !newState.selectCollectionsModalOpen;
            break;
        case MIX_AND_MATCH_OPEN_SEARCH_COLLECTIONS_MODAL:
            newState = _cloneDeep(state);
            newState.searchCollectionModalType = action.modalType;
            newState.selectCollectionsModalOpen = true;
            break;
        case MIX_AND_MATCH_OPEN_ADD_PRODUCT_GROUP_MODAL:
            newState = _cloneDeep(state);
            newState.selectProductsModalType = action.modalType;
            newState.selectProductsModalOpen = action.open;
            if (newState.selectProductsModalOpen) {
                newState.selectedProducts = [];
            }
            break;
        case MIX_AND_MATCH_EDIT_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            newState.selectProductsModalOpen = true;
            newState.selectProductsModalType = 'edit_product_group';
            newState.productGroupIndex = action.idx;
            newState.selectedProducts = _cloneDeep(newState.offer.offer.product_groups[action.idx].products);
            break;
        case MIX_AND_MATCH_CREATING_OFFER:
            newState = _cloneDeep(state);
            newState.creatingOffer = action.bool;
            break;
        case MIX_AND_MATCH_SAVING_OFFER:
            newState = _cloneDeep(state);
            newState.savingOffer = action.bool;
            break;
        case MIX_AND_MATCH_SEARCHING_COLLECTION:
            newState = _cloneDeep(state);
            newState.searchedCollectionsSpinner = action.bool;
            break;
        case MIX_AND_MATCH_SEARCHING_PRODUCT:
            newState = _cloneDeep(state);
            newState.searchedProductSpinner = action.bool;
            break;
        case MIX_AND_MATCH_DELETING_OFFER:
            newState = _cloneDeep(state);
            newState.deletingOffer = action.bool;
            break;
        case MIX_AND_MATCH_ADD_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            if (newState.selectProductsModalType === 'offer') {
                newState.offer.offer.product_groups.push({
                    group_type: 'products',
                    products: _cloneDeep(newState.selectedProducts),
                    min_qty: 0,
                })
            } else if (newState.selectProductsModalType === 'edit_product_group') {
                newState.offer.offer.product_groups[newState.productGroupIndex].products =
                  _cloneDeep(newState.selectedProducts);
            } else if (newState.searchCollectionModalType === 'trigger') {
                newState.offer.triggers.product_groups.push({
                    group_type: 'products',
                    products: _cloneDeep(newState.selectedProducts),
                })
            }
            break;
        case MIX_AND_MATCH_SELECT_COLLECTION:
            newState = _cloneDeep(state);
            newState.selectCollectionsModalOpen = false;
            if (newState.searchCollectionModalType === 'offer') {
                newState.offer.offer.product_groups.push({
                    group_type: 'collection',
                    id: action.id,
                    title: action.title,
                    min_qty: 0,
                })
            } else if (newState.searchCollectionModalType === 'trigger') {
                newState.offer.triggers.product_groups.push({
                    group_type: 'collection',
                    id: action.id,
                    title: action.title,
                })
            }
            break;
        case MIX_AND_MATCH_DELETE_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            newState.offer.offer.product_groups.splice(action.id, 1);
            break;
        case MIX_AND_MATCH_ADD_PRODUCT:
            newState = _cloneDeep(state);
            newState.selectedProducts.push({
                id: action.node.id,
                title: action.node.title,
                handle: action.node.handle,
                imgSrc: action.node.images?.edges[0]?.node?.originalSrc,
            });
            break;
        case MIX_AND_MATCH_REMOVE_PRODUCT:
            newState = _cloneDeep(state);
            _remove(newState.selectedProducts, {id: action.id});
            break;
        case MIX_AND_MATCH_DELETE_TRIGGER_PRODUCT_GROUP:
            newState = _cloneDeep(state);
            newState.offer.triggers.product_groups.splice(action.id, 1);
            break;
        case MIX_AND_MATCH_MIN_QTY_CHANGE:
            newState = _cloneDeep(state);
            if (action.num < 0) action.num = 0;
            newState.offer.offer.product_groups[action.idx].min_qty = parseInt(action.num);
            break;
        case MIX_AND_MATCH_MIN_QTY_FOR_BUYING_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.product_groups[action.idx].min_qty_for_buying = action.text;
            break;
        case MIX_AND_MATCH_MAX_QTY_FOR_BUYING_CHANGE:
            newState = _cloneDeep(state);
            if (action.text < 1) action.text = 1;
            newState.offer.offer.product_groups[action.idx].max_qty_for_buying = parseInt(action.text);
            break;
        case MIX_AND_MATCH_PRODUCT_GROUP_STRING_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.product_groups[action.idx].texts = newState.offer.offer.product_groups[action.idx].texts || {};
            newState.offer.offer.product_groups[action.idx].texts[newState.locale] = newState.offer.offer.product_groups[action.idx].texts[newState.locale] || {};
            newState.offer.offer.product_groups[action.idx].texts[newState.locale][action.attr] = action.text;
            break;
        case MIX_AND_MATCH_LIMIT_NUM_ITEMS_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.product_groups[action.idx].limit_num_of_items = action.text === 'yes';
            if (newState.offer.offer.product_groups[action.idx].limit_num_of_items &&
                !newState.offer.offer.product_groups[action.idx].max_qty_for_buying) {
                newState.offer.offer.product_groups[action.idx].max_qty_for_buying =
                    newState.offer.offer.product_groups[action.idx].min_qty;
            }
            break;
        case MIX_AND_MATCH_BOOL_CHANGE:
            newState = _cloneDeep(state);
            newState.offer[action.attr] = action.text === 'yes';
            break;
        case MIX_AND_MATCH_LOAD_OFFERS_ERROR:
            newState = _cloneDeep(state);
            newState.loadOffersError = true;
            break;
        case MIX_AND_MATCH_TIER_MIN_QTY_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.tiers[action.idx].min_qty = parseInt(action.num);
            if (newState.offer.offer.discount.tiers[action.idx].min_qty < 0) newState.offer.offer.discount.tiers[action.idx].min_qty = 0;
            break;
        case MIX_AND_MATCH_TIER_DISCOUNT_AMOUNT_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.tiers[action.idx].discount_amount = parseFloat(action.num);
            if (newState.offer.offer.discount.tiers[action.idx].discount_amount < 0) newState.offer.offer.discount.tiers[action.idx].discount_amount = 0;
            break;
        case MIX_AND_MATCH_TIER_DISCOUNT_TYPE_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.tiers[action.idx].discount_type = action.text;
            break;
        case MIX_AND_MATCH_DELETE_TIER:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.tiers.splice(action.idx, 1);
            break;
        case MIX_AND_MATCH_OPEN_PARENT_PRODUCT_MODAL:
            newState = _cloneDeep(state);
            newState.selectParentProductModalOpen = action.bool;
            break;
        case MIX_AND_MATCH_ADD_TIER:
            newState = _cloneDeep(state);
            newState.offer.offer.discount.tiers = newState.offer.offer.discount.tiers || [];
            const maxQty = newState.offer.offer.discount.tiers.reduce((acc, tier) => acc > tier.min_qty ? acc : tier.min_qty, 0);
            newState.offer.offer.discount.tiers.push({
                min_qty: maxQty + 1,
                discount_amount: 0,
                discount_type: 'percentage',
            });
            break;
        case MIX_AND_MATCH_SET_STATE:
            newState = _cloneDeep(state);
            newState.offersState = action.state;
            break;
        case MIX_AND_MATCH_SETTINGS_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.values[action.attr] = action.text;
            break;
        case MIX_AND_MATCH_SETTINGS_BOOL_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.values[action.attr] = action.bool === 'yes';
            break;
        case MIX_AND_MATCH_OFFER_TEXT_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.texts[newState.locale][action.attr] = action.text;
            break;
        case MIX_AND_MATCH_TRIGGER_LOCATION_CHANGE:
            newState = _cloneDeep(state);
            newState.offer.trigger_locations[action.attr] = action.bool;
            break;
        case MIX_AND_MATCH_SETTING_COLOR_CHANGE:
            newState = _cloneDeep(state);
            newState.settings.values[action.attr] = action.hex;
            break;
        default:
            return state;
    }
    return newState;
};

export default mixAndMatch;
