import { connect } from 'react-redux'
import ChooseRules from "../../components/products/ChooseRules";
import {
    cancel,
    chooseMappingName,
    onMappingAbovePriceChange, onMappingAboveQuantityChange,
    onMappingBelowPriceChange, onMappingBelowQuantityChange,
    onShowMappingAbovePriceChanged, onShowMappingAboveQuantityChanged,
    onShowMappingBelowPriceChanged, onShowMappingBelowQuantityChanged,
    onShowMappingOnCartChanged,
    onShowMappingOnProductChanged, saveMapping,
    showDestProducts
} from "../../actions/products";

const mapStateToProps = state => ({
    nextText: 'Next',
    isShowOnProduct: state.products.isShowMappingOnProduct,
    isShowOnCart: state.products.isShowMappingOnCart,
    isShowAbovePrice: state.products.isShowMappingAbovePrice,
    isShowAboveQuantity: state.products.isShowMappingAboveQuantity,
    abovePrice: state.products.mappingAbovePrice,
    currencySymbol: state.products.currencySymbol,
    isShowBelowPrice: state.products.isShowMappingBelowPrice,
    isShowBelowQuantity: state.products.isShowMappingBelowQuantity,
    belowPrice: state.products.mappingBelowPrice,
    belowQuantity: state.products.mappingBelowQuantity,
    aboveQuantity: state.products.mappingAboveQuantity,
    isSavingMapping: state.products.isSavingMapping,

    showSave: true,
});

const mapDispatchToProps = (dispatch) => ({
    next: () => dispatch(chooseMappingName()),
    cancel: () => dispatch(cancel()),
    back: () => dispatch(showDestProducts()),
    onShowOnProductChanged: bool => dispatch(onShowMappingOnProductChanged(bool)),
    onShowOnCartChanged: bool => dispatch(onShowMappingOnCartChanged(bool)),
    onShowAbovePriceChanged: bool => dispatch(onShowMappingAbovePriceChanged(bool)),
    onShowBelowPriceChanged: bool => dispatch(onShowMappingBelowPriceChanged(bool)),
    onShowBelowQuantityChanged: bool => dispatch(onShowMappingBelowQuantityChanged(bool)),
    onShowAboveQuantityChanged: bool => dispatch(onShowMappingAboveQuantityChanged(bool)),
    onAbovePriceChange: num => dispatch(onMappingAbovePriceChange(num)),
    onBelowPriceChange: num => dispatch(onMappingBelowPriceChange(num)),
    onAboveQuantityChange: num => dispatch(onMappingAboveQuantityChange(num)),
    onBelowQuantityChange: num => dispatch(onMappingBelowQuantityChange(num)),
    onSave: () => dispatch(saveMapping()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChooseRules);
