import React from 'react';
import {Button, Card, Checkbox, TextField, TextStyle} from "@shopify/polaris";

const ChooseRules = ({cancel, back, next, nextText, isShowOnProduct, onShowOnProductChanged, isShowOnCart,
                         onShowOnCartChanged, isShowAbovePrice, onShowAbovePriceChanged, abovePrice,
                         onAbovePriceChange, currencySymbol, isShowBelowPrice, onShowBelowPriceChanged,
                         belowPrice, onBelowPriceChange, isShowAboveQuantity, onShowAboveQuantityChanged,
                         aboveQuantity, onAboveQuantityChange, isShowBelowQuantity, onShowBelowQuantityChanged,
                         belowQuantity, onBelowQuantityChange, isNextLoading, nextError, onSave, isSavingMapping,
                         showSave}) => (
    <div className="mapping-table">
        <div className="navigation-wrapper">
            <span className="navigation-button">
                <Button onClick={cancel}>Cancel</Button>
            </span>
            <span className="navigation-button">
                <Button onClick={back}>Back</Button>
            </span>
            <span className="navigation-button">
                <Button onClick={next} loading={isNextLoading} primary>{nextText}</Button>
            </span>
            {showSave && <span className="navigation-button save">
                <Button onClick={onSave} loading={isSavingMapping}>Save</Button>
            </span>}
        </div>
        {nextError && <div><TextStyle variation="negative">Something went wrong</TextStyle></div>}
        <div style={{margin: '15px 0'}}>
            <Card>
                <div style={{padding: '15px'}}>
                    <Checkbox label="Show on product page" checked={isShowOnProduct}
                              onChange={onShowOnProductChanged} />
                    <br/>
                    <Checkbox label="Show on cart page" checked={isShowOnCart}
                              onChange={onShowOnCartChanged} />
                </div>
            </Card>
        </div>
        {isShowOnCart &&
            <div>
                <Card>
                    <div style={{padding: '15px'}}>
                        <Checkbox label="Show only above total cart price" checked={isShowAbovePrice}
                                  onChange={onShowAbovePriceChanged} />
                        {isShowAbovePrice &&
                            <TextField label={"Above price in " + currencySymbol + ' :'} type="number"
                                       value={abovePrice && abovePrice.toString()}
                                       autoComplete={false} onChange={onAbovePriceChange} />
                        }
                    </div>
                </Card>
                <Card>
                    <div style={{padding: '15px'}}>
                        <Checkbox label="Show only below total cart price" checked={isShowBelowPrice}
                                  onChange={onShowBelowPriceChanged} />
                        {isShowBelowPrice &&
                            <TextField label={"Below price in " + currencySymbol + ' :'} type="number"
                                       value={belowPrice && belowPrice.toString()}
                                       autoComplete={false} onChange={onBelowPriceChange} />
                        }
                    </div>
                </Card>
                <Card>
                    <div style={{padding: '15px'}}>
                        <Checkbox label="Show only above cart items count" checked={isShowAboveQuantity}
                                  onChange={onShowAboveQuantityChanged} />
                        {isShowAboveQuantity &&
                            <TextField label="Above quantity:" type="number"
                                       value={aboveQuantity && aboveQuantity.toString()}
                                       autoComplete={false} onChange={onAboveQuantityChange} />
                        }
                    </div>
                </Card>
                <Card>
                    <div style={{padding: '15px'}}>
                        <Checkbox label="Show only below cart items count" checked={isShowBelowQuantity}
                                  onChange={onShowBelowQuantityChanged} />
                        {isShowBelowQuantity &&
                            <TextField label="Below quantity:" type="number"
                                       value={belowQuantity && belowQuantity.toString()}
                                       autoComplete={false} onChange={onBelowQuantityChange} />
                        }
                    </div>
                </Card>
            </div>
        }

    </div>
)

export default ChooseRules;
