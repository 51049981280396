import { connect } from 'react-redux'
import {
    backToMain, exportMappings, overrideExistingMappingsOnImport,
    overrideExistingMappingsOnImportChange,
    toggleOverrideExistingMappingsModal,
    uploadImportMappingsSuccess
} from "../../actions/products";
import ImportMappings from "../../components/products/ImportMappings";
import {emailChange} from "../../actions/data";

const mapStateToProps = state => ({
    importMappingsState: state.products.importMappingsState,
    products: state.products,

    shopName: state.data.shopName,
    token: state.data.token,
    data: state.data,
});

const mapDispatchToProps = dispatch => ({
    backToMain: () => dispatch(backToMain()),
    exportMappings: () => dispatch(exportMappings()),
    uploadSuccess: () => dispatch(uploadImportMappingsSuccess()),
    overrideExistingMappingsOnImportChange: bool => dispatch(overrideExistingMappingsOnImportChange(bool)),
    toggleOverrideExistingMappingsModal: bool => () => dispatch(toggleOverrideExistingMappingsModal(bool)),
    overrideExistingMappingsOnImport: () => dispatch(overrideExistingMappingsOnImport()),
    onEmailChange: text => dispatch(emailChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImportMappings);
