import fetch from "cross-fetch";
import {host} from "./data";

export const INSTALL_SHOP_DOMAIN_CHANGE = 'INSTALL_SHOP_DOMAIN_CHANGE';
export const INSTALLING_ZOORIX = 'INSTALLING_ZOORIX';

export const installShopDomainChange = text => ({
    type: INSTALL_SHOP_DOMAIN_CHANGE,
    text,
});

export const installingZoorix = () => ({
    type: INSTALLING_ZOORIX,
});

export function installZoorix() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(installingZoorix());
        fetch(`https://${host}/data/install?shop_domain=${state.install.shopDomain}`)
            .then(response => {
                if (response.ok) {
                    response.json().then(json => window.open(json.install_url, '_self'));
                } else {
                    console.log('failed')
                }
            })
    }
}
