import fetch from "cross-fetch";
import {host} from "../data";
import {initializeAppV2Wizard} from "./appV2Wizard";

export const INITIALIZE_APP_V2 = 'INITIALIZE_APP_V2';
export const SET_APP_V2_INITIAL_STATE = 'SET_APP_V2_INITIAL_STATE';
export const APP_V2_CLOSE_WIZARD = 'APP_V2_CLOSE_WIZARD';

export const initializeAppV2 = params => ({
    type: INITIALIZE_APP_V2,
    params,
});

export const setAppV2InitialState = json => ({
    type: SET_APP_V2_INITIAL_STATE,
    json,
});

export const appV2CloseWizard = () => ({
    type: APP_V2_CLOSE_WIZARD,
});

export function initAppV2(params) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.appV2.initialized) return;
        dispatch(initializeAppV2(params));
        fetch(`https://${host}/admin_v2/init?shop_name=${params.shop}&token=${params.token}`)
            .then(response => response.json())
            .then(json => {
                dispatch(setAppV2InitialState(json));
                dispatch(initializeAppV2Wizard());
            });
    }
}
