import fetch from "cross-fetch";
import {host, postPayload} from "./data";
import {refreshSyncStatus} from "./status";

export const INIT_CSS_EDITOR = 'INIT_CSS_EDITOR';
export const START_CSS_SAVE = 'START_CSS_SAVE';
export const CSS_SAVE_FAILURE = 'CSS_SAVE_FAILURE';
export const CSS_CHANGE = 'CSS_CHANGE';
export const JS_CHANGE = 'JS_CHANGE';
export const CSS_EDITOR_TOGGLE_REMOVE_VERSION_LOCK_MODAL = 'CSS_EDITOR_TOGGLE_REMOVE_VERSION_LOCK_MODAL';
export const CSS_EDITOR_REMOVING_VERSION_LOCK = 'CSS_EDITOR_REMOVING_VERSION_LOCK';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const CSS_SAVE_SUCCESS = 'CSS_SAVE_SUCCESS';
export const CSS_EDITOR_REMOVE_VERSION_LOCK_SUCCESS = 'CSS_EDITOR_REMOVE_VERSION_LOCK_SUCCESS';

export const initCssEditor = json => ({
    type: INIT_CSS_EDITOR,
    customCss: json.custom_css,
    widgetVersionLock: json.widget_version_lock,
    latestMajor: json.latest_major,
});

export const startCssSave = () => ({
    type: START_CSS_SAVE,
});

export const cssSaveFailure = () => ({
    type: CSS_SAVE_FAILURE,
});

export const cssChange = text => ({
    type: CSS_CHANGE,
    text,
});

export const jsChange = text => ({
    type: JS_CHANGE,
    text,
});

export const cssEditorToggleRemoveVersionLockModal = bool => ({
    type: CSS_EDITOR_TOGGLE_REMOVE_VERSION_LOCK_MODAL,
    bool,
});

export const cssEditorRemovingVersionLock = bool => ({
    type: CSS_EDITOR_REMOVING_VERSION_LOCK,
    bool,
});

export const dismissToast = () => ({
    type: DISMISS_TOAST,
});

export const cssSaveSuccess = () => ({
    type: CSS_SAVE_SUCCESS,
});

export const cssEditorRemoveVersionLockSuccess = () => ({
    type: CSS_EDITOR_REMOVE_VERSION_LOCK_SUCCESS,
});

export function cssSave() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(startCssSave());
        return fetch(`https://${host}/custom_css/save`,
            {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_name: state.data.shopName,
                    token: state.data.token,
                    app_type: state.data.appType,
                    css: state.cssEditor.css,
                    js: state.cssEditor.js,
                })
            })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        dispatch(initCssEditor(json));
                        dispatch(cssSaveSuccess());
                        dispatch(refreshSyncStatus());
                    });
                } else {
                    dispatch(cssSaveFailure());
                }
            });
    }
}

export function cssEditorRemoveVersionLock() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(cssEditorRemovingVersionLock(true));
        const res = await postPayload('data/remove_widget_version_lock', {}, state);
        if (res.ok) {
            dispatch(cssEditorRemovingVersionLock(false));
            dispatch(cssEditorRemoveVersionLockSuccess());
            dispatch(cssEditorToggleRemoveVersionLockModal(false));
        } else {
            console.log('cssEditorRemoveVersionLock failed');
        }
    }
}
